import axios from "@/axios.js"
import _ from 'lodash'
import helper from "./customExtraHelper"

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    customExtraStore: [],
    formData:{},
    deleteDialog: { show: true, priceRuleId: 0, affectedUsers: 0 }
  },
  mutations: {
    SET_PRICE_RULE_LIST(state, priceRules) {     
      state.customExtraStore = priceRules;
    },
    SET_FORM_DATA(state, formData) {
      state.formData = formData;
    },
    ADD_PRICE_RULE(state, priceRule) {
      state.customExtraStore.push(priceRule)
    },
    UPDATE_PRICE_RULE(state, priceRule) {
      const priceRuleIndex = _.findIndex(state.customExtraStore, (item) => item.id === priceRule.priceRuleId)
      state.customExtraStore[priceRuleIndex] = priceRule
    },
    REMOVE_PRICE_RULE(state, priceRuleId) {
      const priceRuleIndex = _.findIndex(state.customExtraStore, (item) => item.id === priceRuleId)
      state.customExtraStore.splice(priceRuleIndex, 1)
    },
    SET_DELETE_DIALOG(state, deleteDialog) {
      state.deleteDialog = deleteDialog
    }
  },
  actions: {
    fetchFormData({ commit },payload) {      
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.customExtraAPIs.formDataAPI,payload);
        axios.get(url)
          .then(response => {
            commit('SET_FORM_DATA', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchPriceRules({ commit }, payload) {
      // disabled to use dummy data
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.customExtraAPIs.listAPI, payload);
        axios.get(url)
          .then(response => {
            commit('SET_PRICE_RULE_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
      /**
       * the response data  structure 
       * [{
            "ruleId": 0,
            "ruleAppId": 0,
            "ruleName": null,
            "rulePriority": 0,
            "ruleIsActive": false,
            "ruleCreated": "0001-01-01T00:00:00"
          }]
       */
    },
    addExtra({ commit }, payload) {
      // disabled to use dummy data
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(
          helper.customExtraAPIs.addAPI, payload.applicationId, payload.groupId);
        axios.post(url, { ruleData: JSON.stringify(payload.data) })
          .then(response => {
            /**
             * Eventually, fetchPriceRules will be executed upon successfull add request
             * So, commit here may not be needed
             * uncomment the following lines if needed
             */
            // commit('ADD_PRICE_RULE', {
            //   ruleId: response.data.ruleId,
            //   ruleAppId: payload.data.applicationId,
            //   ruleName: payload.data.name,
            //   rulePriority: payload.data.priority,
            //   ruleIsActive: payload.data.isActive,
            //   ruleCreated: response.data.ruleCreated,
            // });
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    updateExtra({ commit }, payload) {
      return new Promise((resolve, reject) => {
      const url = helper.StringFormat(helper.customExtraAPIs.updateAPI, payload.applicationId, payload.groupId);
      axios.put(url, { ruleData: JSON.stringify(payload.data) })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })

    },
    removePriceRule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.customExtraAPIs.deleteAPI, payload.applicationId, payload.priceRuleId);
        axios.delete(url)
          .then(response => {
            /**
             * Eventually, fetchPriceRules will be executed upon successfull delete request
             * So, commit here may not be needed
             * uncomment the following lines if needed
             */
            //commit('REMOVE_PRICE_RULE', payload.priceRuleId)
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
  }
}
